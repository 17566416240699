import { hmr, cl } from '@/utils';
import { barbaInit } from '@/barba';
import { globalInit } from '@/global';
import register from '@/components';
import htmx from 'htmx.org';

hmr();

declare global {
  interface Window {
    isDevMode: boolean;
    htmx: typeof htmx;
  }
}

window.htmx = htmx;

(function (doc) {
  register();
  barbaInit();
  globalInit();

  // import('@/htmx').catch(() => new Error('htmx not found'));

  if (!window.isDevMode) {
    import('@/utils/sentry').then(({ default: SentryInit }) => {
      SentryInit({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        maxBreadcrumbs: 50,
      });
    });
  }

  doc.addEventListener('DOMContentLoaded', () => {
    cl(document.documentElement).remove('to-load');
  });
})(document);
