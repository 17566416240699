import mitt, { Emitter } from 'mitt';
import type { ITransitionData } from '@barba/core/dist/core/src/src/defs';

type Events = {
  barba_leave: ITransitionData;
  barba_enter: ITransitionData;
  busy: boolean;
  cancel: void;
  click: MouseEvent
  keydown: KeyboardEvent
  error: CustomEvent
  cart: number
  purchasableId: CustomEvent
  scroll: number
  resize: void
  mapReady: void
  afterSwap: Event
};

export const bus: Emitter<Events> = mitt<Events>();
window.addEventListener('click', (ev) => {
  bus.emit('click', ev);
})

window.addEventListener('keydown', (ev) => {
  bus.emit('keydown', ev);
})

document.body.addEventListener("cart", (ev: CustomEvent) => {
  bus.emit('cart', Number(ev.detail.value));
})

document.body.addEventListener("error", (ev: CustomEvent) => {
  bus.emit('error', ev);
})

document.body.addEventListener("htmx:afterSwap", (ev: CustomEvent) => {
  bus.emit('afterSwap', ev)
})

document.body.addEventListener("mapReady", () => {
  bus.emit('mapReady')
})

let scheduledAnimationFrame1 = false;
let scheduledAnimationFrame2 = false;

function onScroll() {
  if (scheduledAnimationFrame1) {
    return;
  }

  scheduledAnimationFrame1 = true;
  requestAnimationFrame(() => {
    bus.emit('scroll', window.scrollY)
    scheduledAnimationFrame1 = false;
  });
}

window.addEventListener('scroll', onScroll);

function onResize() {
  if (scheduledAnimationFrame2) {
    return;
  }

  scheduledAnimationFrame2 = true;
  requestAnimationFrame(() => {
    bus.emit('resize')
    scheduledAnimationFrame2 = false;
  });
}

window.addEventListener('resize', onResize);
