export default () => {
  const modules = import.meta.glob('./*.ts')

  for (const path in modules) {
    //@ts-ignore
    modules[path]().then(({ default: mod }) => {
      if (!customElements.get(mod.component)) {
        customElements.define(mod.component, mod);
      }
    })
  }
}
