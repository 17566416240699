import { Observer } from '@/utils';

class CustomElement extends HTMLElement {
  static component = 'b-component'
  static observedAttributes = [];
  observe = false;
  observer: Observer;

  constructor() {
    super();
  }

  connectedCallback() {
    this.init()
    if (this.observe) {
      this.observer = new Observer({
        once: true,
        callback: (visible) => {
          if (visible) {
            this.initObserved()
          }
        }
      });
      this.observer.add(this)
    }
  }

  attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    this.changed(name, newValue, oldValue);
  }

  disconnectedCallback() {
    this.destroyed();
  }

  $<T extends HTMLElement>(name: string, patent?: HTMLElement) {
    return (patent || this).querySelector<T>(`[data-el="${name}"]`) || null;
  }

  $$<T extends HTMLElement>(name: string, parent?: HTMLElement) {
    return Array.from((parent || this).querySelectorAll<T>(`[data-el="${name}"]`)) || [];
  }

  getProp(name: string, parent?: HTMLElement) {
    const prop = (parent || this).getAttribute(`data-prop-${name}`);
    if (!prop) {
      console.warn(`No prop found with attribute: data-prop-${name} on component: ${CustomElement.component}`);
    }

    return prop;
  }

  init() {

  }

  initObserved() {

  }

  changed(name: string, newValue: string, oldValue:string,) {

  }

  destroyed() {

  }
}

export { CustomElement }
