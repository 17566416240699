import { Observer, cl } from '.';

export default class LazyVideos {
  selector = '.lazy-video video';
  observer: Observer;

  constructor() {
    this.observer = new Observer({
      rootMargin: '0% 0% 0% 0%',
      threshold: 0,
      once: true,
      callback(visible, el: HTMLVideoElement) {
        if (visible && !cl(el).has('visible-once')) {
          el?.addEventListener('canplay', () => {
            cl(el).add('canplay');
          });
          for (let source in el?.children) {
            const videoSource = el.children[source] as HTMLSourceElement;
            if (videoSource.tagName === 'SOURCE') {
              videoSource.setAttribute('src', videoSource.dataset.src as string);
            }
          }

          el?.load();
        }
      },
    });
    this.observer.add(this.selector);
  }

  update(scope: HTMLElement) {
    this.observer.add(this.selector, scope);
  }
}
