const Classy = (element: HTMLElement | string | HTMLElement[] | undefined) => {
  const el: HTMLElement[] =
    typeof element === 'string'
      ? Array.prototype.slice.call(document.querySelectorAll<HTMLElement>(element), 0)
      : Array.isArray(element)
      ? element
      : [element];

  const forEach = function (array: any[], callback: (el: any, i: number) => void) {
    for (let i = 0; i < array.length; i++) {
      callback(array[i], i);
    }
  };

  function addClass(el: HTMLElement, className: string) {
    if (el) {
      if (el.classList) el.classList.add(className);
      else el.className += ' ' + className;
    }
  }

  function removeClass(el: HTMLElement, className: string) {
    if (el) {
      if (el.classList) el.classList.remove(className);
      else
        el.className = el.className.replace(
          new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'),
          ' ',
        );
    }
  }

  function toggleClass(el: HTMLElement, className: string) {
    if (el) {
      if (el.classList) el.classList.toggle(className);
      else {
        const classes = el.className.split(' ');
        const existingIndex = classes.indexOf(className);
        if (existingIndex >= 0) classes.splice(existingIndex, 1);
        else classes.push(className);
        el.className = classes.join(' ');
      }
    }
  }

  function forEachAndEach(el: any, className: string | string[], method: (...ars: any) => void) {
    const classNames = Array.isArray(className) ? className : [className];
    forEach(el as any, (e) => {
      forEach(classNames, (c) => {
        method(e, c);
      });
    });
  }

  const add = (className: string | string[]) => {
    forEachAndEach(el, className, addClass);
    return methods;
  };

  const remove = (className: string | string[]) => {
    forEachAndEach(el, className, removeClass);
    return methods;
  };

  const toggle = (className: string | string[]) => {
    forEachAndEach(el, className, toggleClass);
    return methods;
  };

  const has = (className: string) => el[0]?.classList.contains(className);

  const methods = { add, remove, toggle, has };

  return methods;
};

export default Classy;
