import { cl, replaceBodyClassName } from '@/utils';
import type { Core } from '@barba/core/dist/core/src/src/core';
import { bus } from '@/bus';
import select from 'dom-select';


export let barba: Core | null = null;
export let busy: boolean = false;

cl('.barba-container').remove('to-load');

document.body.addEventListener("barba", (e: CustomEvent) => {
  if (e.detail.value && e.detail.value !== 'null') {
    go({ url: e.detail.value as string });
  }
})

function setBackButton(container?: HTMLElement) {
  select('.go-back', container)?.addEventListener('click', (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    barba.go(barba.history.previous?.url || '/').then();
  });
}

export function barbaInit() {
  import('@barba/core').then(({ default: brb }) => {
    barba = brb;
    brb.init({
      timeout: 10000,
      debug: false,
      preventRunning: true,
      cacheIgnore: ['/checkout'],
      prefetchIgnore: ['/checkout'],
      requestError: ({ href }) => {
        window.location.href = href;
      },
      prevent: ({ href }: any) => {
        return (
          href &&
          (href.indexOf('/actions/') > -1 || !!href.match(/\.(xls|xlsx|doc|docx|pdf)$/)?.length)
        );
      },
    });

    setBackButton();

    barba.hooks.before(() => {
      setBusy(true);
    });

    barba.hooks.leave((data) => {
      bus.emit('barba_leave', data)
      cl(document.body).add('in-transition')
    });

    barba.hooks.enter((data) => {
      bus.emit('barba_enter', data)
      window.scrollTo(0, 0);
      window.htmx.process(data.next.container);
      setBackButton(data.next.container);

      requestAnimationFrame(() => {
        cl(data.next.container).remove('to-load');
        cl(document.body).remove(['in-transition', 'fade-transition'])
      })

      replaceBodyClassName(`page-${data.next.namespace}`);
    });

    barba.hooks.after(() => {
      setBusy(false);
    });
  });
}

export function go({ url, trigger = 'barba' }: { url: string; trigger?: string }) {
  if (barba) {
    barba.go(url, trigger as any).then();
  }
}

export function forceGo({ url, trigger = 'barba' }: { url: string; trigger: string }) {
  if (barba) {
    barba.cache.delete(url);
    barba.page(url, trigger as any, undefined, undefined).then();
  }
}
export function setBusy(b: boolean) {
  busy = b;
  bus.emit('busy', b)
  if (b) {
    cl(document.body).add('is-busy');
  } else {
    cl(document.body).remove('is-busy');
  }
}
