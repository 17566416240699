import select from 'dom-select';
import { cl, LazyVideos } from '@/utils';
import { bus } from '@/bus';


const videoObserver = new LazyVideos();


function sameLinkClick(parent?: HTMLElement) {
  select.all<HTMLAnchorElement>('a', parent).forEach((link) => {
    if (link.origin === window.location.origin) {
      link.addEventListener('click', (ev) => {
        if (link.href === window.location.href) {
          ev.preventDefault();
          ev.stopPropagation();

          bus.emit('cancel');
        }
      });
    }
  });
}

function setActiveLinks(parent?: HTMLElement) {
  const pageSegments = new URL(window.location.href).pathname.split('/').filter((f) => !!f);
  const links = select.all<HTMLAnchorElement>('.active-links', parent);

  links.forEach((link) => {
    if (link.origin === window.location.origin) {
      const linkSegments = new URL(link.href).pathname.split('/').filter((f) => !!f);
      const last = link.getAttribute('data-last') !== null;
      if (
        (pageSegments.length === 0 && linkSegments.length === 0) ||
        (last
          ? pageSegments?.[pageSegments.length - 1] === linkSegments?.[linkSegments.length - 1]
          : pageSegments?.[0] === linkSegments?.[0])
      ) {
        cl(link).add('active');
      } else {
        cl(link).remove('active');
      }
    }
  });
}

function checkProductAvailability(parent?: HTMLElement) {
  setTimeout(() => {
    const variants = select.all('[data-variant]', parent);
    const ids = variants.map((v) => v.getAttribute('data-variant'));
    if (ids.length) {
      fetch(`/actions/monotone/web/check-product-availability?variants=${ids.join(',')}`)
        .then((r) => r.json())
        .then((resp) => {
          if (!resp || resp.length === 0) {
            return;
          }

          variants.forEach((variantEl) => {
            const availabilityEl = select('.availability-tag', variantEl);
            const variantId = variantEl.getAttribute('data-variant');
            const availability = resp.find((a: any) => a.id === Number(variantId));
            if (availability && availabilityEl) {
              if (availability.outStock) {
                availabilityEl.innerHTML = '<span class="tag !bg-gray">Sold</span>';
              } else if (availability.onHold) {
                availabilityEl.innerHTML = `<span class="tag ${availability.onHold === 'In Cart' ? '!bg-green' : '!bg-orange'}">${availability.onHold}</span>`;
              }
            }
          })
        }).catch(() => null);
    }
  }, 1000)
}

export function globalInit() {
  sameLinkClick();
  setActiveLinks();
  checkProductAvailability();

  bus.on('barba_enter', ({ next }) => {
    videoObserver.update(next.container);
    sameLinkClick(next.container);
    setActiveLinks();
    checkProductAvailability(next.container);
  })
}
