import select from 'dom-select';

export function isTouch() {
  return (
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    (navigator as any).msMaxTouchPoints > 0
  );
}

export function isDesktop(width = 1280) {
  return !isTouch() && window.innerWidth >= width;
}

export function isHidden(el: HTMLElement) {
  const style = window.getComputedStyle(el);
  return style.display === 'none';
}

export function getScreenRefreshRate(
  callback: (fps: number, coll: number[]) => void,
  runIndefinitely: boolean = false,
) {
  let requestId: number = 0;
  let callbackTriggered = false;
  runIndefinitely = runIndefinitely || false;

  if (!window.requestAnimationFrame) {
    window.requestAnimationFrame =
      (window as any).mozRequestAnimationFrame || (window as any).webkitRequestAnimationFrame;
  }

  let DOMHighResTimeStampCollection: number[] = [];

  let triggerAnimation = function (DOMHighResTimeStamp: number) {
    DOMHighResTimeStampCollection.unshift(DOMHighResTimeStamp);

    if (DOMHighResTimeStampCollection.length > 10) {
      let t0 = DOMHighResTimeStampCollection.pop() || 0;
      let fps = Math.floor((1000 * 10) / (DOMHighResTimeStamp - t0));

      if (!callbackTriggered) {
        callback.call(undefined, fps, DOMHighResTimeStampCollection);
      }

      callbackTriggered = !runIndefinitely;
    }

    requestId = window.requestAnimationFrame(triggerAnimation);
  };

  window.requestAnimationFrame(triggerAnimation);

  // Stop after half second if it shouldn't run indefinitely
  if (!runIndefinitely) {
    window.setTimeout(function () {
      window.cancelAnimationFrame(requestId);
      requestId = 0;
    }, 500);
  }
}

export function replaceBodyClassName(name: string) {
  const classes = document.body.className
    .split(' ')
    .filter((clName) => clName.indexOf('page-') === -1);
  classes.push(name);
  document.body.className = classes.join(' ');
}

export function toKebabCase(str: string) {
  return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}

function pxToNumber(px: string) {
  return Number(px.replace('px', ''));
}

type Breakpoints = 'sm' | 'md' | 'to-md' | 'lg' | 'xl' | 'hd' | 'max';

export function breakpoint(width: Breakpoints) {
  const breakpoints = {
    sm: '',
    md: '(min-width: 768px)',
    'to-md': '(max-width: 767px)',
    lg: '(min-width: 1024px)',
    xl: '(min-width: 1366px)',
    hd: '(min-width: 1600px)',
    max: '(min-width: 2000px)',
  };
  return breakpoints[width];
}

export function breakpointCallback(
  width: Breakpoints,
  callback: ({ matches }: { matches: boolean }) => void,
) {
  const mql = window.matchMedia(breakpoint(width));
  mql.addEventListener('change', callback);
  callback({ matches: mql.matches });
}

export function isBreakpoint(width: Breakpoints) {
  return window.matchMedia(breakpoint(width)).matches;
}

export function checkHttp(url: string) {
  return url.indexOf('http') === 0 ? url : `https://${url}`;
}

export function hmr() {
  // @ts-ignore
  if (import.meta.hot) {
    // @ts-ignore
    import.meta.hot.accept(() => {
      console.log('HMR');
    });
  }
}

export function getHtmlFromUrl(url: string, selector?: string) {
  return fetch(url)
    .then((res) => res.text())
    .then((html) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      return select(selector || 'body > *', doc as unknown as HTMLElement) || null;
    })
    .catch(() => '');
}

export function getCrsf() {
  return fetch('/actions/blitz/csrf/json')
    .then(res => res.json())
    .then(({ token }) => token as string).catch(() => '');
}

export const tick = (callback: (initValue?: any) => any, initValue?: any) => {
  let unsubscribed = false
  let lastValue = initValue

  const handler = () => {
    if (unsubscribed) return

    lastValue = callback(lastValue)
    window.requestAnimationFrame(handler)
  }

  window.requestAnimationFrame(handler)

  return () => {
    unsubscribed = true
  }
}
